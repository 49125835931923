import React, { useState } from "react";
import SocialNavBar from "../../Components/ui/SocialNavBar";
const ContactForm = React.memo(() => {
  // For Captcha code
  const randomString = Math.random().toString(36).slice(8);
  const [captcha, setCaptcha] = useState(randomString);
  const [text, setText] = useState("");
  const [clear, setClear] = useState("");
  const [valid] = useState(false);

  const [EASYOFFICE, setEASYOFFICE] = useState({
    name: "",
    mobile: "",
    mobile2: "",
    email: "",
    address1: "",
    address2: "",
    area: "",
    city: "",
    state: "",
    pin_code: "",
    description: "",
    profession: "",
    product: "EASYOFFICE",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEASYOFFICE({
      ...EASYOFFICE,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClear("")

    fetch("https://www.easyofficesoftware.com/api/add_inquiry")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((EASYOFFICE) => {
        console.log("Form submitted successfully:", EASYOFFICE);
        alert("Thank you For Messageing");
        setText("");

        setCaptcha(Math.random().toString(36).slice(8));
        setEASYOFFICE({
          name: "",
          mobile: "",
          mobile2: "",
          email: "",
          address1: "",
          address2: "",
          area: "",
          city: "",
          state: "",
          pin_code: "",
          description: "",
          profession: "",
          product: "EASYOFFICE",
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const fields = [
    {
      id: "name",
      name: "name",
      placeholder: "Full Name",
      type: "text",
    },
    {
      id: "profession",
      name: "profession",
      placeholder: "Profession",
      type: "text",
    },
    {
      id: "mobile",
      name: "mobile",
      placeholder: "Mobile Number",
      type: "number",
    },
    { id: "email", name: "email", placeholder: "Email", type: "email" },
    {
      id: "city",
      name: "city",
      placeholder: "City",
      type: "text",
    },
  ];

  // For captcha code
  const refreshString = () => {
    setCaptcha(Math.random().toString(36).slice(8));
  };

  return (
    <>
      <div
        id="contact"
        className="flex justify-center relative sm:mt-20 mt-[1000px] sm:px-20"
      >
        <div className="mb-20 py-8 sm:flex justify-evenly sm:space-x-9 items-start px-5 w-full max-w-7xl bg-white">
          <div className="sm:w-1/2">
            <div>
              <div>
                <p className="text-black text-4xl font-bold text-center">
                  Connect Us
                </p>
              </div>
              <div className="flex mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-geo-alt-fill"
                  viewBox="0 0 16 16"
                  className="mt-1 mr-3"
                >
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                </svg>
                <p className="mb-0 text-black font-bold text-xl">
                  505, Sukhsagar Complex, <br />
                  Nr. Fortune Landmark,
                  <br /> Usmanpura Cross Road, <br />
                  Ahmedabad - 380013
                </p>
              </div>
              <div className="flex flex-col items-start mt-5">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                    className="mr-3 mt-1"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                  </svg>
                  <a
                    href="mailto:info@easyofficesoftware.com"
                    className="mb-0 text-black font-bold text-xl"
                  >
                    info@easyofficesoftware.com
                  </a>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                    className="mr-3 mt-1"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                  </svg>
                  <a
                    href="mailto:info@electrocom.in"
                    className="mb-0 text-black font-bold text-xl"
                  >
                    info@electrocom.in
                  </a>
                </div>
              </div>
              <div className="flex flex-col items-start mt-5">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                    className="mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <a
                    href="tel:07927562400"
                    rel="noopener"
                    className="mb-0 text-black font-bold text-xl"
                  >
                    079-27562400 (Multi-line)
                  </a>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                    className="mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <a
                    href="tel:07935014600"
                    rel="noopener"
                    className="mb-0 text-black font-bold text-xl"
                  >
                    079-35014600 (Multi-line)
                  </a>
                </div>
              </div>
              <div className="flex items-center mt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                  className="mr-3"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
                <a
                  href="whatsapp://send?text=Hello!&phone=+917046062400"
                  target="_blank"
                  rel="noreferrer"
                  className="mb-0 text-black font-bold text-xl"
                >
                  +91 70460 62400
                </a>
              </div>
              <div className="mt-10">
                <p>Connect with us :</p>
                <SocialNavBar Color={"bg-black"} />
              </div>
            </div>
          </div>
          <div className="sm:w-1/2 relative sm:isolate text-center">
            <div class="hidden sm:block absolute inset-0 -z-10 overflow-hidden">
              <svg
                class="absolute left-[max(50%,25rem)] top-0 h-full w-full -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(30rem_30rem_at_top,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width="100"
                    height="100"
                    x="50%"
                    y="-1"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    stroke-width="0"
                  />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
                />
              </svg>
            </div>
            <p className="font-bold text-4xl text-black pb-4">Inquire</p>
            <form onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 sm:gap-3">
                {fields.map(
                  ({ id, name, placeholder, type, extraClass, cols = "" }) => (
                    <div className={`relative ${cols}`} key={id}>
                      <input
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                        value={EASYOFFICE[name]}
                        onChange={handleChange}
                        className={`w-full border placeholder-black py-4 px-3 mb-2 border-gray-400 rounded-lg shadow-xl ${extraClass}`}
                        required={id === "name" && "mobile" && "city"}
                      />
                    </div>
                  )
                )}
                <div className={`relative`}>
                  <select
                    name="state"
                    id="state"
                    onChange={handleChange}
                    required
                    className={`w-full border py-4 px-3 mb-2 border-gray-400 rounded-lg shadow-xl`}
                  >
                    <optgroup>
                      <option value="1">State</option>
                      <option value="44">Andaman and Nicobar Islands</option>
                      <option value="2">Andhra Pradesh</option>
                      <option value="3">Arunachal Pradesh</option>
                      <option value="4">Assam</option>
                      <option value="5">Bihar</option>
                      <option value="6">Chandigarh</option>
                      <option value="7">Chhattisgarh</option>
                      <option value="8">Dadra and Nagar Haveli</option>
                      <option value="9">Daman and Diu</option>
                      <option value="10">Delhi</option>
                      <option value="11">Goa</option>
                      <option value="12">Gujarat</option>
                      <option value="13">Haryana</option>
                      <option value="14">Himachal Pradesh</option>
                      <option value="15">Jammu and Kashmir</option>
                      <option value="16">Jharkhand</option>
                      <option value="17">Karnataka</option>
                      <option value="19">Kerala</option>
                      <option value="20">Lakshadweep</option>
                      <option value="21">Madhya Pradesh</option>
                      <option value="22">Maharashtra</option>
                      <option value="23">Manipur</option>
                      <option value="24">Meghalaya</option>
                      <option value="25">Mizoram</option>
                      <option value="26">Nagaland</option>
                      <option value="29">Odisha</option>
                      <option value="31">Pondicherry</option>
                      <option value="32">Punjab</option>
                      <option value="33">Rajasthan</option>
                      <option value="34">Sikkim</option>
                      <option value="35">Tamil Nadu</option>
                      <option value="36">Telangana</option>
                      <option value="37">Tripura</option>
                      <option value="39">Uttarakhand</option>
                      <option value="41">Uttarpradesh</option>
                      <option value="42">West Bengal</option>
                      <option value="43">Other</option>
                    </optgroup>
                  </select>
                </div>
                <div className="relative col-span-2">
                  <input
                    id="description"
                    name="description"
                    // value={description}
                    placeholder="Remark"
                    onChange={handleChange}
                    className={`w-full border py-4 px-3 mb-2 placeholder-black border-gray-400 rounded-lg shadow-xl pb-10`}
                  />
                </div>
              </div>
              {/* Captcha Code */}
              <div className="flex justify-center items-center mt-3 py-2">
                <div className="flex items-center bg-gray-100 px-2 py-1">
                  <div className="mr-2 text-black line-through text-4xl">
                    {captcha}
                  </div>
                  <button onClick={() => refreshString()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="black"
                      class="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                    </svg>
                  </button>
                </div>
                <div className="ml-4">
                  <textarea
                    name="Enter Captcha"
                    value={text}
                    cols="15"
                    rows="1"
                    className="px-4 py-1 bg-gray-100"
                    placeholder="Enter Captcha..."
                    error={valid}
                    onChange={(e) => setText(e.target.value)}
                    helperText={valid && "Invalid Captcha"}
                  ></textarea>
                </div>
                <div>
                  <p
                    className={`mb-0 ml-3 text-black ${
                      text.length > 0 ? "block" : "hidden"
                    } ${text === captcha ? "hidden" : ""}`}
                  >
                    Enter Valid Captcha
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <button
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  disabled={text !== captcha}
                  className={`py-2 px-5 bg-primary rounded-lg text-primary-foreground font-semibold shadow active:bg-blue-800 hover:bg-primary/90 disabled:bg-red-400 disabled:opacity-80 disabled:text-white disabled:cursor-not-allowed`}
                  type={"submit"}
                  label={"Submit"}
                >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default ContactForm;
